import React from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
//CSS
import styles from '../Routes.module.css'
import Button from '../../Components/Utils/Button/Button'
//Components
import Card from '../../Components/Evaluate/Card/Card'
import TabbedView from '../../Components/Utils/TabbedView/TabbedView'
import { ITab } from '../../Types/ITab'
import RouteWrapper from '../../Components/Utils/RouteWrapper/RouteWrapper'
import EvaluateActive from './EvaluateActive'
import EvaluatePlanned from './EvaluatePlanned'
import EvaluateArchive from './EvaluateArchive'

const Evaluate = () => {

  const tabs: ITab[] = [
    {
      title: "Active",
      active: true
    },
    {
      title: "Planned",
      active: false
    },
    {
      title: "Archive",
      active: false
    }
  ]

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Evaluate</h1>
        <Button colour="#EAB33C" size="Small" textColour="#000" id="tour-new-activity">New Activity</Button>
      </div>
      <TabbedView data={tabs} />
      <div className={styles.body}>
        {
          <Routes>
            <Route path="" element={<EvaluateActive />} />
            <Route path="planned" element={<EvaluatePlanned />} />
            <Route path="archive" element={<EvaluateArchive />} />
          </Routes>
        }
      </div>

      {/*



<Routes>
            <Route path="/moreevaluate" element={<Evaluate />}/>
          </Routes>


        <Card title="Provider T Level Plan V4" percentage={100} />
        <Card title="Ofsted Education Inspection Framework FES Long V5" percentage={34} />
        <Card title="Provider T Level Plan V2" percentage={82} />
        <Card title="DFE T Level Plan V4" percentage={61} />

*/}

    </div>
  )
}

export default Evaluate