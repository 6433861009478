import { useNavigate, Link } from 'react-router-dom';
//CSS
import styles from './ResultItem.module.css'


type ResultItemProps = {
    item: string,
    type: string
}

const ResultItem = ({item, type} : ResultItemProps) => {

const navigate = useNavigate();

  return (
    <Link to={`/${item.toLowerCase()}`}>
        <div className={styles.container} onClick={() => { navigate(item) }}>
            <div className={styles.left}>
                {/*
                    <div className={styles.imageContainer}>
                        <img className={styles.image} src={item} />
                    </div>
                */
                }
                <p className={styles.title}>{item}</p>
            </div>
        </div>
    </Link>
  )
}

export default ResultItem