import React from 'react';
import styles from './Skeleton.module.css';

type SkeletonProps = {
    loading: boolean,
    children: React.ReactNode;
}

const Skeleton = ( { loading, children } : SkeletonProps) => {
    if (loading) {

    var avatar = `${styles.avatar} ${styles.skeleton}`
    var text = `${styles.text} ${styles.skeleton}`

    return <div className={styles.container}>
            <div className={styles.info}>
                <img src='/img/sparkle.svg' className={styles.overlay}/>
                <div className={text}></div>
                <div className={text}></div>
                <div className={text}></div>
                <div className={text}></div>
            </div>
        </div>;
    }
    
    return <>{children}</>;
};

export default Skeleton;