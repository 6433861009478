import React from 'react'
//CSS
import styles from './RouteWrapper.module.css';

type RouteWrapperProps = {
    children: React.ReactNode;
}

const RouteWrapper = ({children} : RouteWrapperProps) => {
  return (
    <div className={styles.container}>
        {children}
    </div>
  )
}

export default RouteWrapper