import React from 'react'
import styles from './TagsBar.module.css'

type TagsBarProps = {
    tags: string[]
}

const TagsBar = ({tags} : TagsBarProps) => {
  return (
    <div className={styles.outer}>
      <h2>Tags: </h2>
      <div className={styles.tagsContainer}>
        <p>{tags.join(", ")}</p>
      </div>
    </div>
    
  )
}

export default TagsBar