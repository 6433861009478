import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// CSS
import styles from './TabbedView.module.css';
import { ITab } from '../../../Types/ITab';
// Components
import Tab from './Tab';

type TabbedViewProps = {
    data: ITab[];
};

const TabbedView = ({ data }: TabbedViewProps) => {

    const [tabs, setTabs] = useState<ITab[]>([]);

    useEffect(() => {
        setTabs(data);
    }, [data]);

    const handleTabClick = (index: number) => {
        setTabs((prevTabs) =>
            prevTabs.map((tab, i) => ({
                ...tab,
                active: i === index,
            }))
        );
    };

    return (
        <div className={styles.container}>
            {tabs.map((item, index) => (
                <Link key={index} to={`/evaluate/${item.title !== "Active" ? item.title.toLowerCase() : ""}`}>
                    <Tab
                        key={index}
                        title={item.title}
                        active={item.active}
                        clickHandler={() => handleTabClick(index)}
                    />
                </Link>
            ))}
        </div>
    );
};

export default TabbedView;
