import React, { useEffect, useState } from 'react'
//CSS
import styles from './Card.module.css';
//Components
import Button from '../../Utils/Button/Button';
import ProgressBar from '../../Utils/ProgressBar/ProgressBar';
import { AreaDisplayInfo } from '../../../Types/AreaDisplayInfo/AreaDisplayInfo';
import { Link } from 'react-router-dom';

type CardProps = {
    areaDisplayInfo: AreaDisplayInfo,
    url: string,
    editHandler?: () => void,
}


const Card = ({ areaDisplayInfo: {
    id,
    title,
    dueDate,
    status,
    participantCount,
    groups,
    roles,
    organisations,
    departments,
    percentage
}, url }: CardProps) => {

    const [progress, setProgress] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setProgress(percentage)
        }, 200)
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.status}>
                <div className={styles.statusLeft}>
                    <ProgressBar percentage={progress} />
                    <p>{`${percentage}%`}</p>
                </div>
                <div className={styles.statusRight}>
                    {percentage == 100 ? (
                        <Button size="Small" colour="#52BFA2" textColour="#FFF">Complete</Button>
                    ) : (
                        <Button size="Small" colour="#E25A74" textColour="#FFF">Incomplete</Button>
                    )}
                </div>
            </div>
            <div className={styles.title}>
                <h2>{title}</h2>
            </div>
            <div className={styles.info}>
                <div className={styles.infoLeft}>
                    <p>Due: Today</p>
                    <p className={styles.participants}>{participantCount} Participants</p>
                    <p>Group: {groups?.join(", ")}</p>
                </div>
                <div className={styles.infoRight}>
                    <p>Role: {roles?.join(", ")}</p>
                    <p>Organisation: {organisations?.join(", ")}</p>
                    <p>Department: {departments?.join(", ")}</p>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerLeft}>
                    <Button size="Small" colour="#FFF" textColour="#000" border="rgba(0,0,0,0.2)">Archive</Button>
                    <Button size="Small" colour="#FFF" textColour="#000" border="rgba(0,0,0,0.2)">Export</Button>
                </div>
                <div className={styles.footerRight}>
                    <Button size="Small" colour="#FFF" textColour="#000" border="rgba(0,0,0,0.2)">
                        {percentage < 100 ? "Complete Framework" : "Edit Framework"}
                    </Button>
                    <Link to={url}>
                        <Button size="Small" colour="#FFF" textColour="#000" border="rgba(0,0,0,0.2)">
                            {percentage < 100 ? "Complete Summary" : "Edit Summary"}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Card