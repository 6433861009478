import React, { useState } from 'react'
//Components
import Button from '../Utils/Button/Button'
import NotificationsDropdown from './NotificationsDropdown';
//CSS
import styles from './NotificationItem.module.css';

const NotificationItem = () => {

    const [showNotifications, setShowNotifications] = useState(false);
    const [count, setCount] = useState(3);

    return (
        <div className={styles.container}>
            <Button className='tour-button-container' size="Icon" colour={"rgba(0,0,0,0"} clickHandler={() => setShowNotifications(!showNotifications)}>
                <div className={styles.container}>
                    <img src='/img/NavIcons/notifications.svg' />
                    {count > 0 && (
                        <span className={styles.count}>
                            {count}
                        </span>)}
                </div>
            </Button>
            {showNotifications && <NotificationsDropdown />}
        </div>
    )
}

export default NotificationItem