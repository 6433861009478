import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
//CSS
import styles from './TemplateView.module.css';
import { IQuestionnaireTemplate } from '../../../../Types/Products/IQuestionnaireTemplate';
import { Route, Routes, useParams } from 'react-router-dom';
import QuestionSectionCriteria from '../../../../Components/Products/QuestionSectionCriteria';
import SectionsDrawer from '../../../../Components/SectionsDrawer/SectionsDrawer';
import { ICriteriaItem } from '../../../../Types/Products/ICriteriaItemData';
import { IQuestionnaireSection } from '../../../../Types/Products/IQuestionnaireSection';
//import { questionnaireTemplates } from '../../../../Data/QuestionnaireTemplates';
import LoadingIcon from '../../../../Components/Utils/LoadingIcon/LoadingIcon';
import { IDrawerItem, IDrawerSection } from '../../../../Types/Drawer/ISectionsDrawerItem';
import { DrawerProvider } from '../../../../Components/SectionsDrawer/DrawerContext';

type TemplateViewProps = {
}


const TemplateView = ({ }: TemplateViewProps) => {

    const {templateId} = useParams();
    const basePath = `/portal/questionnaire-editor`

    const [template, setTemplate] = useState<IQuestionnaireTemplate>();
    var [questionnaireSection, setQuestionnaireSection] = useState<IQuestionnaireSection>();
    var [questionnaireSectionCriteria, setQuestionnaireSectionCriteria] = useState<ICriteriaItem>();

    var [drawerSections, setDrawerSections] = useState<IDrawerSection[]>([]);

    const getTemplate = async () => {
        if(templateId){
            //const response = await GetQuestionnaireTemplateById(templateId);

          // const response = questionnaireTemplates.filter(item => item .id === templateId)[0];
            //setTemplate(response);
        }

        if(template){
          const sections: IDrawerSection[] = template.questionnaireSections.map((section) => {
    
            const drawerSection: IDrawerSection = {
              id: section.id,
              title: section.title,
              status: section.completionStatus,
              items: section.criteria.map(item => {
    
                const drawerItem: IDrawerItem = {
                  id: item.id,
                  title: item.title,
                  url: `${section.id}/${item.id}`
                }
    
                return drawerItem
              })
            }
    
            return drawerSection
          })
    
          setDrawerSections(sections)
        }
    }


    useEffect(() => {
        getTemplate();
    }, [templateId])

    if (template) {
        return (
          <></>
        )
      } else {
        return <LoadingIcon/>
      }
}

export default TemplateView