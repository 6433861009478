import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios";

export interface Person {
    id: number,
    name: string
}

interface PersonState {
    persons: Person[]
}

const initialState:PersonState = {
    persons: []
}

// Using axios and try...catch in createAsyncThunk
export const fetchPerson = createAsyncThunk("person/FetchPerson", async (_, thunkApi) => {
    try {
        const response = await axios.get("API_ENDPOINT_HERE"); // replace with actual endpoint
        return response.data; // assuming response.data is the expected payload
    } catch (error: any) {
        // Optional: You can customize error handling here
        return thunkApi.rejectWithValue(error.message);
    }
});

export const PersonSlice = createSlice({
    name: "Person",
    initialState,
    reducers:{
        addPerson:(state, action: PayloadAction<{name: string}>) => {
            state.persons.push({
                id: state.persons.length,
                name: action.payload.name
            })
            console.log('Person added to state.')
        }
    }
})

export default PersonSlice.reducer;
export const {addPerson} = PersonSlice.actions;