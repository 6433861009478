import React, { useEffect, useState } from 'react'
import styles from './PagedCardCollection.module.css';
import { AreaDisplayInfo } from '../../../../Types/AreaDisplayInfo/AreaDisplayInfo';
import CardCollection from '../CardCollection';
import Paging from '../../Paging/Paging';
import Skeleton from '../../Skeleton/Skeleton';
import { useAuth0 } from '@auth0/auth0-react';
import { GetPagedArchivedEvaluateAreasByUser, GetPagedEvaluateAreasByUser } from '../../../../API/Evaluate/Evaluate';

type PagedCardCollectionProps = {
    archived: boolean
}

const PagedCardCollection = ({archived} : PagedCardCollectionProps) => {

    const {user} = useAuth0();

    const endpoint = archived? GetPagedArchivedEvaluateAreasByUser : GetPagedEvaluateAreasByUser;

    const pageSize = 3;
    const [evaluateAreas, setEvaluateAreas] = useState<AreaDisplayInfo[]>();
    const [activePage, setActivePage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);

    const getEvaluateAreas = async () => {
      const data = await endpoint(activePage, pageSize);
    
      setPageCount(Math.ceil(1 / pageSize));
    
      const extraItem: AreaDisplayInfo = {
        id: "demo",
        title: "Inspection Framework",
        dueDate: new Date(),
        status: "Incomplete",
        participantCount: 0,
        groups: [],
        roles: ["O"],
        organisations: [],
        departments: [],
        percentage: 0,
        legacy: false
      };
    
      setEvaluateAreas([extraItem, ...data.items]);
    };

    useEffect(() => {
          getEvaluateAreas();
      }, [activePage]);

      const pageChanged = (page: number) => {
        setActivePage(page)
      }
      
      if(evaluateAreas){
        return (
          <div className={styles.outerContainer}>
           <CardCollection areaDisplayInfos={evaluateAreas}/>
           <Paging activePage={activePage} pageCount={pageCount} pageChanged={pageChanged} />
          </div>
        )
      }
      else{
        return <>
          <Skeleton loading={true}>
            <div></div>
          </Skeleton>
          <Skeleton loading={true}>
            <div></div>
          </Skeleton>
          <Skeleton loading={true}>
            <div></div>
          </Skeleton>
        </>
      }
    

    
}

export default PagedCardCollection