import React, { useEffect, useState } from 'react';
import styles from './FractionalBar.module.css';
import { FractionalBarItem } from '../../../Types/FractionalBar/FractionalBarItem';
import { IGrade } from '../../../Types/IGrade';
import { GetGradeColour } from '../../../Data/ColourData';

interface FractionalBarProps {
  items: FractionalBarItem[],
}

const FractionalBar: React.FC<FractionalBarProps> = ({ items }) => {
  
  const [totalCount, setTotalCount] = useState<number>(items.length);

  useEffect(() => {
    setTotalCount(items.length);
  }, [items]);

  // Define the order for the grades
  const gradeOrder: IGrade[] = ["Red", "Amber", "Green", "Blue", "Not Set"];

  // Sort the items based on the grade order
  const sortedItems = items.sort((a, b) => {
    return gradeOrder.indexOf(a.grade) - gradeOrder.indexOf(b.grade);
  });

  const markers = [];
  for (let i = 0; i <= totalCount; i += 2) {
    markers.push(i);
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        {sortedItems.map((item, index) => (
          <div
            key={index}
            style={{
              background: GetGradeColour(item.grade),
              flex: item.count,
            }}
          />
        ))}
      </div>
      <div className={styles.section}>
        {markers.map((marker, index) => (
            <p key={index}></p>
        ))}
      </div>
      <div className={styles.section}>
        {markers.map((marker, index) => (
          <div key={index}>{marker}</div>
        ))}
      </div>
    </div>
  );
};

export default FractionalBar;