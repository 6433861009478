import React from 'react'
import styles from './LoadingScreen.module.css';
import LoadingIcon from '../LoadingIcon/LoadingIcon';

const LoadingScreen = () => {
  return (
    <div className={styles.container}>
        <LoadingIcon />
    </div>
  )
}

export default LoadingScreen