import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { ReactNode, useEffect } from 'react';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export const AxiosInterceptor = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const requestInterceptor = async (config: any) => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      } catch (error) {
        console.error('Error obtaining access token:', error);
      }
      return config;
    };

    const interceptor = axiosInstance.interceptors.request.use(requestInterceptor);

    return () => {
      axiosInstance.interceptors.request.eject(interceptor);
    };
  }, [getAccessTokenSilently]);

  return <>{children}</>;
};