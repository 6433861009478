import React from 'react'
//CSS
import styles from './ProgressBar.module.css';

type ProgressBarProps = {
    percentage: number;  // Percentage to fill the progress bar (0-100)
};

const ProgressBar = ({ percentage }: ProgressBarProps) => {
    return (
        <div className={styles.progressBarContainer}>
            <div
                className={`${styles.progressBarFill} ${percentage > 75 ? styles.green : percentage > 50 ? styles.yellow : ''}`}
                style={{ width: `${percentage}%` }}
            ></div>
        </div>
    )
}

export default ProgressBar