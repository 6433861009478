import React, { useEffect, useRef } from 'react';
//CSS
import styles from './Routes.module.css';
import { useAppDispatch, useAppSelector } from '../Redux/Store/Store';
import { addPerson } from '../Redux/Store/Slices/person';
import Button from '../Components/Utils/Button/Button';
import { getQuestionnaireByAreaId, updateQuestionnaireGrade, updateQuestionnaireQuestion, updateQuestionnaireSectionStatus, UpdateQuestionnaireSectionStatusPayload } from '../Redux/Store/Slices/questionnaire';
import { IQuestionnaireQuestion } from '../Types/Products/IQuestionnaireQuestion';
import { IGrade } from '../Types/IGrade';
import { ICompletionStatus } from '../Types/ICompletionStatus';
//Components
//Data

type ReduxTestProps = object;

const ReduxTest = ({  } : ReduxTestProps) => {

    const dispatch = useAppDispatch();
    const questionnaire = useAppSelector(state => state.questionnaire.questionnaire)

    useEffect(() => {
      dispatch(getQuestionnaireByAreaId("1"));
      dispatch(updateQuestionnaireGrade("Amber"))
    }, [])

    const updateQuestion = () => {
      let question = {
        ...questionnaire?.questionnaireSections[0].criteria[0].questions[1],
        answer: "This is redux"
      } as IQuestionnaireQuestion;  
      dispatch(updateQuestionnaireQuestion(question))
    }

    const updateGrade = () => {
      const grade: IGrade = questionnaire?.overallGrade === "Green"? "Red" : "Green";
      dispatch(updateQuestionnaireGrade(grade))
    }

    const updateCompletion = () => {
      const section = questionnaire?.questionnaireSections[0]
      const status: ICompletionStatus = section?.completionStatus === "Complete"? "Incomplete" : "Complete";

      const payload: UpdateQuestionnaireSectionStatusPayload = {
        sectionId: section!.id,
        status: 'Complete'
      }
      dispatch(updateQuestionnaireSectionStatus(payload))
    }

  return (
    <div className={styles.container}>

      <h1>{questionnaire?.title}</h1>

      <h1>{questionnaire?.questionnaireSections[0].criteria[0].questions[1].answer}</h1>

      <h1>{questionnaire?.questionnaireSections[0].completionStatus}</h1>

      <h1>{questionnaire?.overallGrade}</h1>

      <button onClick={updateCompletion}>update Completion</button>

      <button onClick={updateGrade}>Grade</button>

      <button onClick={updateQuestion}>Update Questionnaire</button>
      
    </div>
  );
};

export default ReduxTest;