import React from 'react';
//CSS
import styles from './AnalyseBot.module.css';
//Components
//Data

type AnalyseBotProps = {
  
}

const AnalyseBot = ({  } : AnalyseBotProps) => {
  return (
    <div className={styles.container}>
      
    </div>
  );
};

export default AnalyseBot;