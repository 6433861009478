import React from 'react'
import { AreaDisplayInfo } from '../../../Types/AreaDisplayInfo/AreaDisplayInfo'
import Card from '../../Evaluate/Card/Card'
import style from './CardCollection.module.css'

type CardCollectionProps = {
    areaDisplayInfos : AreaDisplayInfo[]
}

const CardCollection = ({areaDisplayInfos} : CardCollectionProps) => {

  return (
    <div className={style.cardsContainer}>
        {areaDisplayInfos.map((item) => {
            return <Card key={item.id} url={`/evaluate/${item.id}`} areaDisplayInfo={item}/>
        })}
    </div>
  )
}

export default CardCollection