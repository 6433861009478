import React, { useEffect, useState } from 'react'
//CSS
import styles from './SearchBar.module.css';
//Components
import ResultItem from './ResultItem/ResultItem';

type SearchBarProps = {
    data: string[] | undefined,
    preset?: string,
    type: string
}

const SearchBar = ({data, preset, type} : SearchBarProps) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState<string[]>([]);

    useEffect(() => {
        setSearchTerm(preset || "");
    }, [preset]);

    useEffect(() => {
        if(data) {
        const filteredResourceResults = data.filter((item: string) => {
            const searchLower = searchTerm.toLowerCase();
            const titleMatch = item.toLowerCase().includes(searchLower);
            //const tagsMatch = item.tags.some(tag => tag.toLowerCase().includes(searchLower));
            return titleMatch;
            //return titleMatch || tagsMatch;
        });
        if(filteredResourceResults){
            setResults(filteredResourceResults);
        }
    }
    }, [searchTerm, data]);

  return (
    <div className={`${styles.container} search-bar`}>
        <div className={styles.inputContainer}>
            <span className={styles.icon}>
                <img src='/img/search-icon.svg' />
            </span>
            <input value={searchTerm} className={styles.input} placeholder='Search...' onChange={(e) => {
                    setSearchTerm(e.target.value);
            }}/>
        </div>
        <div className={styles.resultsContainer}>
        {searchTerm && results.length > 0 && (
            <div className={styles.results}>
                {results.map((result) => {
                    return <ResultItem item={result} type={type} />
                })}
            </div>
        )}
        </div>
    </div>
  )
}

export default SearchBar