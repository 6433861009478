import React from 'react'
//CSS
import styles from './Routes.module.css'
//Components

const Dashboard = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Dashboard</h1>
      </div>
      <div className={styles.body}>

      </div>
    </div>
  )
}

export default Dashboard