import React from 'react'
//CSS
import styles from './CurateDashboardBanner.module.css';
import { useNavigate } from 'react-router-dom';
import Button from '../Utils/Button/Button';
//Components

const CurateDashboardBanner = () => {

    const navigate = useNavigate();

  return (
    <div className={styles.container}>
        <div className={styles.left}>
            <h1>Questionnaire Editor</h1>
            <p>Use the assistance of AI to create and edit questionnaires for Self Assessment templates.</p>
            <Button clickHandler={() => navigate('/portal/questionnaire-editor')}>
              Get Started
            </Button>
        </div>
        <div className={styles.right}>
            <img src='/img/curate-robot.png' />
        </div>
    </div>
  )
}

export default CurateDashboardBanner