import React, { ReactNode, useState } from 'react';
// CSS
import styles from './DropDownContainer.module.css';

type DropDownContainerProps = {
  title: string,
  children: ReactNode
};

const DropDownList: React.FC<DropDownContainerProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.dropdown}>
      <button
        onClick={toggleDropdown}
        className={`${styles.dropbtn} ${isOpen ? styles.open : ''}`}
      >
        {title}
        <span className={styles.arrow}>▾</span>
      </button>
      {isOpen && (
        <div className={styles.dropdownContent}>
         {children}
        </div>
      )}
    </div>
  );
};

export default DropDownList;