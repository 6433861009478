export default class Dictionary<TKey, TValue> {
    
    private items = new Map<TKey, TValue>();

    constructor(initialEntries?: [TKey, TValue][]) {
        this.items = new Map<TKey, TValue>(initialEntries); 
    }

    add(key: TKey, value: TValue): void {
        this.items.set(key, value);
    }

    containsKey(key: TKey): boolean {
        return this.items.has(key);
    }

    tryGetValue(key: TKey, defaultValue: TValue | undefined): TValue | undefined {
        return this.items.has(key) ? this.items.get(key)! : defaultValue; // Non-null assertion (!) since we've checked existence
    }

    remove(key: TKey): boolean {
        return this.items.delete(key);
    }

    clear(): void {
        this.items.clear();
    }

    get keys(): TKey[] {
        return Array.from(this.items.keys());
    }

    get values(): TValue[] {
        return Array.from(this.items.values());
    }

    get count(): number {
        return this.items.size;
    }

    forEach(callbackfn: (value: TValue, key: TKey, map: Map<TKey, TValue>) => void): void {
        this.items.forEach(callbackfn);
    }

    // Additional methods for iteration (entries, etc.) if needed
}