import React from 'react'
import styles from './QuestionSectionTextArea.module.css'

type QuestionSectionTextAreaProps = {
  title?: string;
  className: string;
  placeholder: string;
  value: string;
  onChangeHandler?: (value: string) => void;  // Function to pass updated value
};

const QuestionSectionTextArea = ({
  title,
  className,
  placeholder,
  value,
  onChangeHandler,
}: QuestionSectionTextAreaProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Call the passed in handler with the new value
    if (onChangeHandler) {
      onChangeHandler(e.target.value);
    }
  };

  return (
    <textarea
      onChange={handleChange}
      className={className + ` ${styles.answer}`}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default QuestionSectionTextArea;