import React from 'react'
//CSS
import styles from './NotificationDropdownItem.module.css';

type NotificationDropdownItemProps = {
  action: string,
  user: string
}

const NotificationDropdownItem = ({action, user} : NotificationDropdownItemProps) => {
  return (
    <div className={styles.container}>
        <p>
            <span className={styles.bold}>{user}&nbsp;</span>{action}
        </p>
    </div>
  )
}

export default NotificationDropdownItem