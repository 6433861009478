import React, { ReactNode } from 'react'
//CSS
import styles from './Button.module.css'

type ButtonProps = {
    id?: string
    clickHandler?: () => void,
    className?: string,
    border?: string,
    children?: ReactNode,
    colour?: string | null,
    textColour?: string | null
    size?: "Small" | "Icon" | null
}

const Button = ({id,  border, clickHandler, className, children, colour, textColour, size}: ButtonProps) => {

    // Combine styles into a single object
    const style = {
        ...(colour && { backgroundColor: colour }),
        ...(textColour && { color: textColour }),
        ...(border && {border: `1px solid ${border}`})
    };

  return (
            <button 
                onClick={clickHandler ? () => clickHandler() : undefined}
                className={
                    !className ? `${styles.container} ${size ? styles.small : ""}`:
                    `${styles.container} ${size === "Small" ? styles.small : size === "Icon" ? styles.icon : ""} ${className}`
                }
                style={style}
                id={id}
            >
                {children}
            </button>
  )
}

export default Button