import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { TourProvider } from '@reactour/tour';
import { Step } from './Types/Step';
import { AxiosInterceptor } from './Authentication/AxiosInterceptor';

import { Provider } from 'react-redux';
import { store } from './Redux/Store/Store';

const steps: Step[] = [
  {
    selector: '.search-bar',
    content: 'Click here to search for a specific page'
  },
  {
    selector: '.nav-item-evaluate',
    content: 'Click here to go to Evaluate'
  },
  {
    selector: '#tour-new-activity',
    content: 'Click here to Create a new activity'
  },
  {
    selector: '.nav-item-improve',
    content: 'Click here to go to Improve'
  },
  {
    selector: '.nav-item-develop',
    content: 'Click here to go to Develop'
  },
  {
    selector: '.evaluate-tour-1',
    content: 'Click here to log out'
  }
]

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain="mesma.eu.auth0.com"
        clientId="6cEckUpj4BeV1Ck4cffeThPI5fZuiLCO"
        authorizationParams={{
          redirect_uri: window.location.origin
        }}
      >
        <Provider store={store}>
          <AxiosInterceptor>
            <TourProvider steps={steps}>
              <App />
            </TourProvider>
          </AxiosInterceptor>
        </Provider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>
);