import React from 'react'
//CSS
import styles from './LoadingIcon.module.css';

const LoadingIcon = () => {
  return (
    <div className={styles.container}>
        <div className={`${styles.circle} ${styles.mesmaRed}`}></div>
        <div className={`${styles.circle} ${styles.mesmaYellow}`}></div>
        <div className={`${styles.circle} ${styles.mesmaGreen}`}></div>
    </div>
  )
}

export default LoadingIcon