import React, { useState } from 'react'
//CSS
import styles from './NotificationsDropdown.module.css';
//Components
import NotificationDropdownItem from './NotificationDropdownItem';

const NotificationsDropdown = () => {

  const [activeWindow, setActiveWindow] = useState<"Read" | "Unread">("Unread");

  const toggleWindow = () => {
    if(activeWindow === "Unread") {
      setActiveWindow("Read")
    } else {
      setActiveWindow("Unread")
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={ activeWindow === "Unread" ? styles.active : ''} onClick={() => toggleWindow()}>Unread</p>
        <p className={ activeWindow === "Read" ? styles.active : ''} onClick={() => toggleWindow()}>Read</p>
      </div>
      <div className={styles.body}>
        {activeWindow === "Unread" ? (
          <>
            <NotificationDropdownItem action='invited you to complete a self assessment.' user="A Person" />
            <NotificationDropdownItem action="invited you to an area Ofsted Education Inspection Framework FES Long V5" user="A Person" />
            <NotificationDropdownItem action="invited you to an area Ofsted Education Inspection Framework FES Long V5" user="A Person" />
            <NotificationDropdownItem action="invited you to an area Ofsted Education Inspection Framework FES Long V5" user="A Person" />
            <NotificationDropdownItem action="invited you to an area Ofsted Education Inspection Framework FES Long V5" user="A Person" />
            <NotificationDropdownItem action="invited you to an area Ofsted Education Inspection Framework FES Long V5" user="A Person" />
            <NotificationDropdownItem action="invited you to an area Ofsted Education Inspection Framework FES Long V5" user="A Person" />
          </>
        ) : (
          <>
            <NotificationDropdownItem action="invited you to an area Ofsted Education Inspection Framework FES Long V5" user="A Person" />
            <NotificationDropdownItem action="invited you to an area Ofsted Education Inspection Framework FES Long V5" user="A Person" />
            <NotificationDropdownItem action="invited you to an area Ofsted Education Inspection Framework FES Long V5" user="A Person" />
            <NotificationDropdownItem action="invited you to an area Ofsted Education Inspection Framework FES Long V5" user="A Person" />
          </>
        )
        }
      </div>
    </div>
  )
}

export default NotificationsDropdown