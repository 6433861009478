import { configureStore, createSelector } from "@reduxjs/toolkit";
import { PersonSlice } from "./Slices/person";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { QuestionnaireSlice } from "./Slices/questionnaire";

export const store = configureStore({
    reducer:{
        person: PersonSlice.reducer,
        questionnaire: QuestionnaireSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch:()=> typeof store.dispatch=useDispatch;
export const useAppSelector:TypedUseSelectorHook<ReturnType<typeof store.getState>>=useSelector;