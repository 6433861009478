import React from 'react';
// CSS
import styles from './Tab.module.css';

type TabProps = {
    title: string;
    active: boolean;
    clickHandler?: () => void;
};

const Tab = ({ title, active, clickHandler }: TabProps) => {
    return (
        <div className={styles.container} onClick={clickHandler}>
            <p className={`${styles.tab} ${active ? styles.active : ''}`}>{title}</p>
        </div>
    );
};

export default Tab;
