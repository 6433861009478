import React, { useState } from 'react';
import { IDropDownListItem } from '../../../Types/DropDownList/IDropDownListItem';
// CSS
import styles from './DropDownList.module.css';

type DropDownListProps = {
  value: string;
  placeholder: string;
  data: IDropDownListItem[];
  onChange?: (selectedItem: IDropDownListItem) => void;
  style?: React.CSSProperties; // Optional additional styles
};

const DropDownList: React.FC<DropDownListProps> = ({
  value,
  placeholder,
  data,
  onChange,
  style,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IDropDownListItem | null>(data.find(x => x.text === value) ?? null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item: IDropDownListItem) => {
    setSelectedItem(item);
    setIsOpen(false);
    if (onChange) {
      onChange(item);
    }
  };

  const selectedText = selectedItem ? selectedItem.text : placeholder;

  // Merge the passed styles with the dynamic styles
  const combinedStyles = {

    ...style, // Merge any additional styles from the props
  };

  return (
    <div className={styles.dropdown}>
      <button
        onClick={toggleDropdown}
        className={`${styles.dropbtn} ${isOpen ? styles.open : ''}`}
        style={combinedStyles}
      >
        {selectedText}
        <span className={styles.arrow}>▾</span> {/* This Unicode character represents a down arrow */}
      </button>
      {isOpen && (
        <ul className={styles.dropdownContent}>
          {data.map((item) => (
            <li
              key={item.text}
              onClick={() => handleItemClick(item)}
              className={styles.dropdownItem}
            >
              {item.text}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropDownList;