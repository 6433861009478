import React from 'react'
//CSS
import styles from '../Routes.module.css';
import CurateDashboardBanner from '../../Components/CurateBanner/CurateDashboardBanner';

const Portal = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Portal</h1>
      </div>
      <div className={styles.body}>

        <CurateDashboardBanner/>

      </div>
    </div>
  )
}

export default Portal