import React, { createContext, useContext, useState, ReactNode } from 'react';  
import { IDrawerItem, IDrawerSection } from '../../Types/Drawer/ISectionsDrawerItem';  
  
interface DrawerContextType {  
  activeItem: IDrawerItem | null;  
  setActiveItem: (item: IDrawerItem) => void;  
  activeSection: IDrawerSection | null,
  setActiveSection: (item: IDrawerSection) => void;  
}  
  
const DrawerContext = createContext<DrawerContextType | undefined>(undefined);  
  
export const DrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {  
  const [activeItem, setActiveItem] = useState<IDrawerItem | null>(null);  
  const [activeSection, setActiveSection] = useState<IDrawerSection | null>(null);  
  
  return (  
    <DrawerContext.Provider value={{ activeItem, setActiveItem, activeSection, setActiveSection }}>  
      {children}  
    </DrawerContext.Provider>  
  );  
};  
  
export const useDrawer = () => {  
  const context = useContext(DrawerContext);  
  if (context === undefined) {  
    throw new Error('useDrawer must be used within a DrawerProvider');  
  }  
  return context;  
};  