import Dictionary from "../Classes/Dictionary";
import { IGrade } from "../Types/IGrade";

type GetColourProps = {
    type: "Evaluate" | "Enquire" | "Improve" | "Develop",
}


export const GetColour = (type : "Evaluate" | "Enquire" | "Improve" | "Develop" | "Generic" | "Red" | "Green" | "Yellow" | "Blue" | "Amber") => {

    const productColours = new Dictionary<string, string>([
        ["Evaluate", "#E25A74"],
        ["Enquire", "#E25A74"],
        ["Improve", "#52BFA2"],
        ["Develop", "#EAB33C"],
        ["Generic", "#24394F"],

        ["Red", "#E25A74"],
        ["Green", "#52BFA2"],
        ["Yellow", "#EAB33C"],
        ["Amber", "#EAB33C"],
        ["Blue", "#24394F"],
        ["Not Set", "#24394F"],
    ]);

    return productColours.tryGetValue(type, undefined);
}

export const GetGradeColour = (type : IGrade) => {

    const productColours = new Dictionary<string, string>([
        ["Red", "#E25A74"],
        ["Green", "#52BFA2"],
        ["Amber", "#EAB33C"],
        ["Blue", "#24394F"],
        ["Not Set", "#efefef"],
    ]);

    return productColours.tryGetValue(type, undefined);
}