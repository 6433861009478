import { useNavigate } from 'react-router-dom';
//CSS
import styles from './NavbarItem.module.css';
import { NavbarItem } from '../../../Types/NavbarItem';
//Data
import { GetColour } from '../../../Data/ColourData';

type NavbarItemProps = {
    item: NavbarItem,
    statusHandler: (id: number) => void
}

const NavbarListItem = ({ item, statusHandler }: NavbarItemProps) => {

    const navigate = useNavigate();

    const currentPath: string = window.location.pathname;

    const isCurrentPath = (title: string): boolean => {
        if (title.toLowerCase() === "dashboard") {
            return currentPath === "/";
        } else {
            return currentPath.startsWith(`/${title.toLowerCase()}`);
        }
    }

    return (
        <li className={`${styles.container} ${item.class}`}
            onClick={() => { statusHandler(item.id); navigate(item.url) }}
            style={{
                backgroundColor:
                    isCurrentPath(item.title) && item.title === "Evaluate" ? GetColour(item.title) :
                        isCurrentPath(item.title) && item.title === "Improve" ? GetColour(item.title) :
                            isCurrentPath(item.title) && item.title === "Develop" ? GetColour(item.title) :
                                isCurrentPath(item.title) ? '#CFDCE5' : ''
            }}
            >
            <img src={item.icon} alt={item.title} />
            <span>{item.title}</span>
        </li>
    );

};

export default NavbarListItem;