import React, { useEffect } from 'react'
//CSS
import styles from './Paging.module.css';

type PagingProps = {
    activePage: number,
    pageCount: number,
    pageChanged: (newPage: number) => void
}

const Paging = ({activePage, pageCount, pageChanged} : PagingProps) => {

    let pages : number[] = [];

    for (let index = 0; index < pageCount; index++) {
        pages.push(index + 1);
    }

  return (
    <div className={styles.pages}>
        {pages.map((i) => {
            return (<p className={i === activePage ? styles.active : ''} onClick={() => pageChanged(i)} key={i}>{i}</p>)
        })}
    </div>
  )
}

export default Paging