import React from 'react'
import DropDownList from '../DropDownList/DropDownList'
import { IDropDownListItem } from '../../../Types/DropDownList/IDropDownListItem'
import { GetColour, GetGradeColour } from '../../../Data/ColourData'
import { IGrade } from '../../../Types/IGrade'

const GradeData : IDropDownListItem[] = [
    {
        text: "Red",
        colour: GetColour("Red"),
        textColour: "black",
        value: undefined
    },
    {
        text: "Amber",
        colour: GetColour("Yellow"),
        textColour: "black",
        value: undefined
    },
    {
        text: "Green",
        colour: GetColour("Green"),
        textColour: "white",
        value: undefined
    },
    {
        text: "Blue",
        colour: GetColour("Blue"),
        textColour: "white",
        value: undefined
    }
]

type GradeSelectorProps = {
    grade: IGrade,
    onChange?: (selectedItem: IDropDownListItem) => void;
    readOnly?: boolean
}

const GradeSelector = ({grade, onChange, readOnly} : GradeSelectorProps) => {
    if(readOnly){
        return (
            <div style={{padding: "10px", background: GetGradeColour(grade)}}>
                {grade}
            </div>
          )
    }else{
        return (
            <DropDownList onChange={onChange} placeholder={'Select a grade'} data={GradeData} value={grade}/>
          )
    }
}

export default GradeSelector