import styles from './QuestionSectionCriteria.module.css';
import layout from '../../Modules/layout.module.css'
import QuestionSectionTextArea from './QuestionSectionTextArea'
import { IQuestionnaireSection } from '../../Types/Products/IQuestionnaireSection';
import { useEffect, useState } from 'react';
import Skeleton from '../Utils/Skeleton/Skeleton';
import { GenerateAssessmentByQuestionnaireSectionIdAndCriteriaId } from '../../API/Evaluate/Evaluate';
import { ICriteriaItem } from '../../Types/Products/ICriteriaItemData';
import { selectSectionById, selectCriteriaById, generateAssessment } from '../../Redux/Store/Slices/questionnaire';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/Store';

const QuestionSectionResults = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const dispatch = useAppDispatch();
    const { activeCriteriaId, activeSectionId } = useAppSelector(state => state.questionnaire);

    const section = useAppSelector(selectSectionById(activeSectionId));
    const criteria = useAppSelector(selectCriteriaById(activeSectionId, activeCriteriaId));


    useEffect(() => {

        const whatWeDo = criteria?.results?.whatWeDo?.trim();

        const init = async () => {
            await dispatch(generateAssessment())
            setIsLoading(false);
        }
        init()
    }, [])

    const whatWeDoChangedHandler = async (value: string) => {

    }

    const whatWeDoWellChangedHandler = async (value: string) => {
        
    }

    const areasForImprovementChangedHandler = async (value: string) => {
        
    }

    return (
            <section className={layout.column2}>

                <div className={layout.column1}>
                    <h5>What We Do</h5>
                    <Skeleton loading={isLoading}>
                        <QuestionSectionTextArea onChangeHandler={(x) => whatWeDoChangedHandler(x)} className={styles.answer} placeholder='Answer...' value={criteria?.results?.whatWeDo ?? ""} />
                    </Skeleton>
                </div>

                <div className={layout.column1}>
                    <h5>What We Do Well</h5>
                    <Skeleton loading={isLoading}>
                        <QuestionSectionTextArea onChangeHandler={(x) => whatWeDoWellChangedHandler(x)} className={styles.answer} placeholder='Answer...' value={criteria?.results?.whatWeDoWell ?? ""} />
                    </Skeleton>
                </div>

                <div className={layout.column1}>
                    <h5>Areas For Improvement</h5>
                    <Skeleton loading={isLoading}>
                        <QuestionSectionTextArea onChangeHandler={(x) => areasForImprovementChangedHandler(x)} className={styles.answer} placeholder='Answer...' value={criteria?.results?.areasForImprovement ?? ""} />
                    </Skeleton>
                </div>

            </section>
    )
}

export default QuestionSectionResults
