import {NavbarItem} from "../Types/NavbarItem";

export const NavbarData : NavbarItem[] = [
    {
        id: 1,
        title: "Dashboard",
        icon: "/img/NavIcons/dashboard.svg",
        active: true,
        url: "/",
        class: "nav-item-dashboard"
      },
      {
        id: 2,
        title: "Calendar",
        icon: "/img/NavIcons/calendar.svg",
        active: false,
        url: "/calendar",
        class: ""
      },
      {
        id: 3,
        title: "Evaluate",
        icon: "/img/NavIcons/evaluate.svg",
        active: false,
        url: "/evaluate",
        class: "nav-item-evaluate"
      },
      /*{
        id: 3,
        title: "Enquire",
        icon: "/img/NavIcons/evaluate.svg",
        active: false,
        url: "/collections"
      },*/
      {
        id: 4,
        title: "Improve",
        icon: "/img/NavIcons/improve.svg",
        active: false,
        url: "/improve",
        class: "nav-item-improve"
      },
      {
        id: 5,
        title: "Develop",
        icon: "/img/NavIcons/develop.svg",
        active: false,
        url: "/develop",
        class: "nav-item-develop"
      },
      {
        id: 6,
        title: "Library",
        icon: "/img/NavIcons/library.svg",
        active: false,
        url: "/library",
        class: "nav-item-library"
      },
      {
        id: 7,
        title: "Resources",
        icon: "/img/NavIcons/resources.svg",
        active: false,
        url: "/resources",
        class: "nav-item-settings"
      },
      {
        id: 9,
        title: "Portal",
        icon: "/img/NavIcons/code.svg",
        active: false,
        url: "/portal",
        class: "nav-item-settings"
      },
      {
        id: 10,
        title: "Settings",
        icon: "/img/NavIcons/settings.png",
        active: false,
        url: "/settings",
        class: "nav-item-settings"
      },
      {
        id: 11,
        title: "Account",
        icon: "/img/NavIcons/account.png",
        active: false,
        url: "/account",
        class: "nav-item-settings"
      },
      {
        id: 12,
        title: "Redux test",
        icon: "/img/arrow.svg",
        active: false,
        url: "/redux-test",
        class: "nav-item-settings"
      }
]